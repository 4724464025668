import {AnimateOutDurationMs} from '@/components/core/Animation';
import type {StatusProps} from '@/components/core/Status';
import Status from '@/components/core/Status';
import type {Signal} from '@preact/signals-react';
import {signal} from '@preact/signals-react';
import {useSignals} from '@preact/signals-react/runtime';
import clsx from 'clsx';

type StatusSpecProps = Pick<StatusProps, 'type' | 'content'>;

type StatusSpec = {
  id: number;
  props: Omit<StatusProps, 'index'>;
};

let statusId = 0;

const DisplayDuration = 2805;

const statusSpecSignalsSignal = signal<Signal<StatusSpec | null>[]>([]);

function updateStatusSpecSignal({
  id,
  props,
}: {
  id: StatusSpec['id'];
  props: Partial<StatusSpec['props']>;
}) {
  const statusSpecSignal = statusSpecSignalsSignal.value.find((statusSpecSignal) => {
    return statusSpecSignal.value?.id === id;
  });
  if (statusSpecSignal?.value) {
    statusSpecSignal.value = {
      ...statusSpecSignal.value,
      props: {
        ...statusSpecSignal.value.props,
        ...props,
      },
    };
  }
}

export function displayStatus(props: StatusSpecProps) {
  const id = ++statusId;
  const statusSpec: StatusSpec = {
    id,
    props: {
      ...props,
      animationPhase: 'in',
    },
  };
  const statusSpecSignal = signal(statusSpec);
  statusSpecSignalsSignal.value = [...statusSpecSignalsSignal.value, statusSpecSignal];

  setTimeout(() => {
    statusSpecSignal.value.props.animationPhase = 'out';
    statusSpecSignal.value = {...statusSpecSignal.value};

    setTimeout(() => {
      let otherStatusesExist = false;
      for (let i = 0; i < statusSpecSignalsSignal.value.length; i++) {
        const newStatus = statusSpecSignalsSignal.value[i];
        if (id === newStatus.value?.id) {
          statusSpecSignalsSignal.value[i].value = null;
        } else if (newStatus.value) {
          otherStatusesExist = true;
        }
      }
      if (!otherStatusesExist) {
        // empty entire array if none are left to start positioning over
        statusSpecSignalsSignal.value = [];
      }
      statusSpecSignalsSignal.value = [...statusSpecSignalsSignal.value];
    }, AnimateOutDurationMs);
  }, DisplayDuration);
}

export default function StatusContext({
  children,
}: {
  container?: Element | DocumentFragment;
  children: any;
}) {
  useSignals();
  return (
    <>
      <div className="StatusContext fixed flex flex-col items-center justify-center z-50 w-full">
        {statusSpecSignalsSignal.value.map((statusSpecSignal, i) => {
          const id = statusSpecSignal.peek()?.id;
          if (!statusSpecSignal.value) {
            return null;
          }
          return (
            <Status
              key={id || i}
              className={clsx('mb-1 mt-2 md:mt-4')}
              index={i}
              style={{marginTop: 40 * i}}
              {...statusSpecSignal.value.props}
            />
          );
        })}
      </div>
      {children}
    </>
  );
}
