import {globalNavigatorSignal} from '@/api/GlobalNavigator';
import {displayStatus} from '@/context/StatusContext';
import ErrorLogger from '@/ErrorLogger';
import {TRPCClientError} from '@trpc/client';
import type {typeToFlattenedError, ZodError} from 'zod';

export function constructErrorMeta(error: Error) {
  // zod is not resolving to the same `ZodError` when using `instanceof` check in prod for some reason, but works fine in local dev.
  // instead just check constructor name
  // @see https://github.com/colinhacks/zod/issues/2241
  if (error.name === 'ZodError') {
    // zod error from client
    const {formErrors, fieldErrors} = (error as ZodError).flatten();
    return {
      status: 0,
      message: (error as ZodError).message,
      form: formErrors,
      field: fieldErrors,
    };
  } else if (error instanceof TRPCClientError && error.data?.zodError) {
    // zod error from server
    const {formErrors, fieldErrors} = error.data.zodError as typeToFlattenedError<any>;
    return {
      status: error.data.httpStatus as number,
      message: error.data.message as string,
      form: formErrors,
      field: fieldErrors,
    };
  } else if (error instanceof Response && error.status >= 300 && error.status < 400) {
    // this is a remix redirect, let it bubble up to allow for redirect
    throw error;
  } else {
    ErrorLogger.Log(error as TRPCClientError<any>);
    return {
      error: error as TRPCClientError<any>,
    };
  }
}

export function displayClientError(errorMeta) {
  let content: string | undefined;
  if (errorMeta?.error?.data?.message) {
    content = errorMeta.error.data.message;
  } else if (errorMeta?.status === 404) {
    content = errorMeta.message;
  } else if (errorMeta?.error || errorMeta.field) {
    content = 'An internal error occurred. Please try again later!';
  }
  if (content) {
    displayStatus({
      type: 'error',
      content,
    });
  }
}

export function displayClientErrorFromInput(errorMeta) {
  let content: string | undefined;
  if (errorMeta?.error?.data?.message) {
    content = errorMeta.error.data.message;
  } else if (errorMeta?.error) {
    content = 'An internal error occurred. Please try again later!';
  } else if (errorMeta?.field) {
    content = 'Please correct the errors';
  }
  if (content) {
    displayStatus({
      type: 'error',
      content,
    });
  }
}

export function handleGenericClientError(error: Error) {
  try {
    const errorMeta = constructErrorMeta(error);
    displayClientError(errorMeta);
    return errorMeta;
  } catch (e) {
    if (e instanceof Response && e.status >= 300 && e.status < 400) {
      const location = e.headers.get('Location')!;
      globalNavigatorSignal.value = location;
      return location;
    }
    ErrorLogger.Log(e as Error);
  }
}
