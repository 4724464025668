import {effect, signal} from '@preact/signals-react';
import {useSignals} from '@preact/signals-react/runtime';
import {Navigate} from '@remix-run/react';
import {useEffect} from 'react';

// this exists solely to allow redirects outside of remix context
export const globalNavigatorSignal = signal<string | null>(null);
export default function navigate(location: string) {
  globalNavigatorSignal.value = location;
}

export function GlobalNavigator() {
  useSignals();
  // HACK
  useEffect(() => {
    if (globalNavigatorSignal.value) {
      globalNavigatorSignal.value = null;
    }
  });

  if (globalNavigatorSignal.value) {
    const to = globalNavigatorSignal.value;
    return <Navigate to={to} />;
  }
  return null;
}
